const moment = require('moment');

export function getOrderId(order) {
  if (order) {
    return order.display_id;
    // return order.payment_transaction_id
    //   ? sliceTranId(order.payment_transaction_id)
    //   : `尚未生成 (#${order.id})`;
  }
  return '';
}

export function getTransId(order) {
  if (order && typeof order === 'object') {
    return order.payment_transaction_id
      ? sliceTranId(order.payment_transaction_id)
      : `尚未生成 (#${order.id})`;
  } else if (typeof order === 'string') {
    return sliceTranId(order);
  }
  return '';
}

export function sliceTranId(id) {
  if (id) {
    return `${id.slice(0, 12)}`;
  }
  return '';
}

export function getPaymentProvider(provider) {
  switch (provider) {
    case 'payuni':
      return '統一';
    default:
      return '藍新';
  }
}

export const ORDER_STATE = {
  payment_waiting: '等待付款',
  review_waiting: '稿件未上傳',
  review_pending: '等待審稿',
  review_rejected: '不合格',
  production_waiting: '製作等待中',
  /*
    production_preparing 工單已交付
    production_in_production 製作中
    display用order_items算的， filter 用 production_status
  */
  production_preparing: '工單已交付',
  production_in_production: '製作中',
  production_completed: '製作完成',
  logistic_pending: '出貨未處理',
  logistic_packaging: '打包中',
  logistic_tallying: '物流未完成',
  logistic_transit: '物流未完成', // 已出貨
  completed: '訂單完成',
  voided: '訂單已取消',
  ass_apply: '售後服務處理中',
  refunding: '退款處理中',
  refunded: '已完成退款',
};

export function getDisplayState({order}) {
  let result = order.display_state;
  // if (order.display_state === "production_waiting" && order.order_items) {
  //   let _production_status = order.order_items.map(x => x.production_status);
  //   if (_production_status.some(status => status === "waiting")) {
  //     result = "production_waiting";
  //   } else if (_production_status.some(status => status === "preparing")) {
  //     result = "production_preparing";
  //   } else if (_production_status.some(status => status === "in_production")) {
  //     result = "production_in_production";
  //   }
  // }
  return result;
}

export const PAYMENT_STATUS = {
  all: '全部',
  processing: '未付款',
  waiting: '未付款',
  code_generated: '未付款', //已取得交易序號
  success: '已付款',
  failure: '付款失敗',
};

export const PAYMENT_STATUS_COLOR = {
  processing: '#999',
  waiting: '#999',
  code_generated: '#999', //已取得交易序號
  success: '#4BAA70',
  failure: '#C24C4C', ///"#D7BD23"
};

export const STATUS_DETAIL = {
  ALL: '全部',
  WAIT_OFFLINE: '等待臨櫃繳費中',
  WAIT_ECPAY_CVS: '等待超商付款中',
  WAIT_ECPAY_ATM: '等待ATM繳款中',
};

export const ORDER_TYPE = {
  // credits: "點數訂單", // in payment type
  default: '一般訂單',
  credit: '點數訂單', // in order type
  extra: '補收款訂單',
  custom: '自定義訂單',
  monthly: '月結訂單',
  period: '訂閱制訂單',
};

export function getOrderType(type) {
  return ORDER_TYPE[type] || '一般訂單';
}

export const PAYMENT_TYPE = {
  all: '全部',
  credit: '點數付款',
  credits: '點數付款',
  neweb: '藍新付款',
  payuni: '統一付款',
  ecpay_cvs: '超商付款',
  ecpay: '信用卡付款',
  ecpay_atm: 'ATM繳費',
  offline: '臨櫃匯款',
  monthly: '月結付款',
};

export const WAIT_PAYMENT_TYPE = {
  all: '全部',
  offline: '等待臨櫃繳費中',
  ecpay_cvs: '等待超商付款中',
  ecpay_atm: '等待ATM繳款中',
};

export const PAYMENT_SUB_TYPE = {
  default: '尚未選擇',
  credit: '信用卡付款',
  cvs_cod: '超商取貨付款',
  cvs: '超商繳費',
  atm: 'ATM付款',
  web_atm: '網路ATM付款',
  barcode: '超商條碼繳費',
  line_pay: 'Line Pay',
};

export function getPaymentType(order) {
  if (order) {
    let prefix = '',
      suffix = '';
    switch (order.payment_type) {
      case 'credits':
      case 'offline':
      case 'monthly':
        return PAYMENT_TYPE[order.payment_type];
      case 'neweb':
        return prefix + PAYMENT_SUB_TYPE[order.payment_subtype] + suffix;
      case 'payuni':
        return prefix + PAYMENT_SUB_TYPE[order.payment_subtype] + suffix;
      default:
        break;
    }
  }
  return '---';
}

//string -> json
export const parseCartData = (order) => {
  try {
    return JSON.parse(order.cart.replace(/\\\\/g, '\\'));
  } catch (ex) {
    console.warn('parse cart data error!!');
    return null;
  }
};

export const EXTRA_ORDER_TYPE = {
  shipping_fee: '運費',
  item_fee: '產品加工',
  construction_funds: '施工款項',
  urgent_increase: '急件加價',
  custom: '自定義商品',
};

export function getCalculation(calculations) {
  let _calculations = {
    ...calculations,
  };
  function round(num) {
    return Number(num.toFixed());
  }

  if (calculations) {
    if (!calculations.hasOwnProperty('original_amount')) {
      // 修改銷售額後的結構
      /*
        amount
        discount
        discount_tax
        items_amount
        items_tax
        fee
        fee_tax
        sales
        tax
      */
      return _calculations;
    } else {
      let fee = round(calculations.fee / 1.05);
      let discount = round(calculations.discount_amount / 1.05);
      let items_tax =
        calculations.items_amount - calculations.items_amount_before_tax;
      _calculations = {
        amount: calculations.amount,
        discount: discount * -1,
        discount_tax: (calculations.discount_amount - discount) * -1,
        items_amount: calculations.items_amount_before_tax,
        items_tax: items_tax,
        fee: fee,
        fee_tax: calculations.fee - fee,
        sales: fee - discount + calculations.items_amount_before_tax,
        tax:
          calculations.fee -
          fee +
          items_tax -
          (calculations.discount_amount - discount),
      };
    }
  }
  return _calculations;
}

export function isOrderDisabled(order) {
  if (order) {
    return (
      ['refunding', 'refunded', 'ass_apply', 'voided'].includes(
        order.display_state,
      ) ||
      (order.display_state === 'completed' &&
        moment().diff(order.complete_time, 'days') > 3)
    );
  }
  return true;
}

export function isReturnAppDisabled(order) {
  if (order) {
    return (
      order.payment_status !== 'success' ||
      ['refunding', 'refunded', 'ass_apply', 'voided'].includes(
        order.display_state,
      )
      // || (order.display_state === "completed" &&
      //   moment().diff(order.complete_time, "days") > 3)
    );
  }
  return true;
}
