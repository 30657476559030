import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSVLink} from 'react-csv';
import {message, Button, Spin} from 'antd';
import moment from 'moment';
import {
  PAYMENT_TYPE,
  PAYMENT_STATUS,
  ORDER_TYPE,
  ORDER_STATE,
  getDisplayState,
} from '../Utils/OrderUtil';
import {errorHandler, ErrReport} from '../errors';
import {isAllow} from '../Utils/ReportUtils';
import {useOutlet} from 'reconnect.js';

const PAYMENT_DETAIL_TYPE = {
  CREDIT: '信用卡付款',
  VACC: '銀行 ATM 轉帳付款',
  WEBATM: '網路銀行轉帳付款',
  BARCODE: '超商條碼繳費',
  CVS: '超商代碼繳費',
};

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function getDetail(order, result) {
  if (order.payment_type === 'offline') {
    return order.remittance_number
      ? `${order.remittance_account || ''}\n${order.remittance_number || ''}\n${
          moment(order.remitted_time).format('YYYY-MM-DD HH:mm') || ''
        }`
      : '';
  } else if (result) {
    return PAYMENT_DETAIL_TYPE[result.PaymentType];
  } else {
    return '-';
  }
}

function parseDetail(order) {
  let result = {};
  let detail = order.payment_transaction_detail || '{}';
  detail = detail.replace(/None/g, 'null').replace(/'/g, '"');

  try {
    result = JSON.parse(detail);
  } catch (err) {}
  return result;
}

function mapping(x) {
  /* 
    if element has `issuer` --> refundRecords
    else --> order
  */
  let paymentDetail = parseDetail(x);

  return {
    created: ` ${x.created.slice(0, 10)} ${x.created.slice(11, 16)}`,
    display_id: x.base_order ? ` ${x.base_display_id}` : ` ${x.display_id}`,
    extra_order_display_id: x.base_order ? ` ${x.display_id}` : '',
    order_type: x.order_type ? `${ORDER_TYPE[`${x.order_type}`]}` : '',
    order_display_state: x.display_state
      ? `${ORDER_STATE[x.display_state]}`
      : '',
    buyer_id: x.buyer ? x.buyer.id : '-',
    receiver_name: (() => {
      if (x.order_owner) {
        return x.order_owner.name;
      } else if (x.buyer) {
        return x.receiver_name;
      } else {
        return '-';
      }
    })(),
    payment_type: x.order_owner ? '線下處理' : PAYMENT_TYPE[x.payment_type],
    amount: x.order_owner ? x.amount * -1 : x.amount,
    payment_time: paymentDetail.Result
      ? ` ${paymentDetail.Result.PayTime}`
      : '-',
    payment_detail_type: getDetail(x, paymentDetail.Result),
    payment_status: PAYMENT_STATUS[x.payment_status],
    note: x.note,
    extra_reason: x.order_type === 'extra' ? x.description : '',
  };
}

export default function ExportCsv({filters, children, onClean, filename}) {
  const csvInstance = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [actions] = useOutlet('actions');
  const headers = useRef([
    {label: '建立時間', key: 'created'},
    {label: '訂單編號', key: 'display_id'}, // if its a normal order
    {label: '訂單類型', key: 'order_type'},
    {label: '訂單狀態', key: 'order_display_state'},
    {label: '補收款編號', key: 'extra_order_display_id'}, // if its a extra_order
    {label: '顧客編號', key: 'buyer_id'},
    {label: '顧客姓名', key: 'receiver_name'},
    {label: '付款方式', key: 'payment_type'},
    {label: '付款狀態', key: 'payment_status'},
    {label: '付款細項', key: 'payment_detail_type'}, // from neweb
    {label: '總價', key: 'amount'},
    {label: '付款時間', key: 'payment_time'}, // from neweb
    {label: '備註', key: 'note'},
    {label: '補收款原因', key: 'extra_reason'},
  ]).current;

  const cleanup = useCallback(() => {
    setLoading(false);
    setData([]);
    onClean();
  }, []);

  useEffect(() => {
    // get count
    (async () => {
      let {start, end} = filters;
      if (isAllow(start, end)) {
        setLoading(true);
        try {
          let resp = await actions.getOrders({
            from: start,
            to: end,
            offset: 0,
            limit: 1,
          });

          setCount(resp.count);
        } catch (err) {}
        setLoading(false);
      }
    })();
  }, [filters.start, filters.end, actions]);

  const getRecords = useCallback(async () => {
    let {start, end, offset, limit} = filters;

    if (isAllow(start, end)) {
      try {
        if (offset !== 0 && offset >= count) {
          throw new ErrReport('最小數量已經超過全部數量了');
        }

        // get orders
        let resp = await actions.getOrders({
          ordering: '-created',
          from: start,
          to: end,
          offset,
          limit,
        });

        // get refunds
        // let {results: refunds} = await actions.getRefunds({
        //   ordering: '-created',
        //   from: start,
        //   to: end,
        //   offset,
        //   limit,
        //   status: 'success',
        // });

        let results = resp.results
          // .concat(refunds)
          .sort((a, b) => new Date(b.created) - new Date(a.created))
          .map(mapping);

        if (results.length === 0) {
          throw new ErrReport('查無資料');
        }

        setData(results);
      } catch (err) {
        errorHandler(err);
        cleanup();
      }
    }
  }, [filters]);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        cleanup();
      }, 200);

      return () => {
        clearTimeout(timer);
        cleanup();
      };
    }
  }, [data]);

  return (
    <div>
      {children({
        onClick: () => getRecords(),
        loading,
        count,
      })}
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          headers={headers}
          data={data}
          filename={filename}></CSVLink>
      ) : null}
    </div>
  );
}
