import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSVLink} from 'react-csv';
import {getInvoiceCategory} from '../Utils/InvoiceUtil';
import {INVOICE_STATUS, INVOICE_AFTER_TREATMENT_STATE} from '../dictionary';
import {errorHandler, ErrReport} from '../errors';
import {isAllow} from '../Utils/ReportUtils';
import {useOutlet} from 'reconnect.js';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
function parse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {}
}

function mapping(x) {
  let detail = parse(x.invoice_detail);

  return {
    ...x,
    display_id: `'${x.display_id}`,
    created: x.created ? x.created.slice(0, 18).replace('T', ' ') : '-',
    category: getInvoiceCategory(x),
    invoice_status: INVOICE_STATUS[x.invoice_status],
    sales: detail?.request_data.Amt,
    tax_amount: detail?.request_data.TaxAmt,
    message: detail?.Message,
    after_threatment: INVOICE_AFTER_TREATMENT_STATE[x.after_threatment],
  };
}

export default function ExportCsv({filters, children, onClean, filename}) {
  const csvInstance = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [actions] = useOutlet('actions');
  const headers = useRef([
    {label: '開立時間', key: 'created'},
    {label: '發票號碼', key: 'invoice_number'},
    {label: '開立方式', key: 'category'},
    {label: '訂單編號', key: 'display_id'},
    {label: '月結對帳單編號', key: 'monthly_order'},
    {label: '會員編號', key: 'buyer'},
    {label: '買受人名稱', key: 'buyer_name'},
    {label: '買受人統編', key: 'buyer_ubn'},
    {label: '銷售額', key: 'sales'},
    {label: '稅額', key: 'tax_amount'},
    {label: '總金額', key: 'amount'},
    {label: '發票狀態', key: 'invoice_status'},
    {label: '買受人電子信箱', key: 'buyer_email'},
    {label: '失敗原因', key: 'message'},
    {label: '處理狀態', key: 'after_threatment'},
  ]).current;

  const cleanup = useCallback(() => {
    setLoading(false);
    setData([]);
    onClean();
  }, []);

  useEffect(() => {
    // get count
    (async () => {
      let {start, end} = filters;
      if (isAllow(start, end)) {
        setLoading(true);
        try {
          let resp = await actions.getInvoices({
            start,
            end,
            offset: 0,
            limit: 1,
          });

          setCount(resp.count);
        } catch (err) {}
        setLoading(false);
      }
    })();
  }, [filters.start, filters.end, actions]);

  const getRecords = useCallback(async () => {
    let {start, end, offset, limit} = filters;
    if (isAllow(start, end)) {
      try {
        if (offset !== 0 && offset >= count) {
          throw new ErrReport('最小數量已經超過全部數量了');
        }

        let resp = await actions.getInvoices({
          ordering: '-created',
          start,
          end,
          offset,
          limit,
        });

        let results = resp.results.map(mapping);
        if (results.length === 0) {
          throw new ErrReport('查無資料');
        }
        setData(results);
      } catch (err) {
        errorHandler(err);
        cleanup();
      }
    }
  }, [filters, actions]);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        cleanup();
      }, 200);

      return () => {
        clearTimeout(timer);
        cleanup();
      };
    }
  }, [data]);

  return (
    <div>
      {children({
        onClick: () => getRecords(),
        loading,
        count,
      })}
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          headers={headers}
          data={data}
          filename={filename}></CSVLink>
      ) : null}
    </div>
  );
}
