import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSVLink} from 'react-csv';
import {message, Button, Spin} from 'antd';
import {MEMBER_TYPE} from '../Utils/MemberUtil';
import {errorHandler, ErrReport} from '../errors';
import {isAllow} from '../Utils/ReportUtils';
import {useOutlet} from 'reconnect.js';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

function mapping(x) {
  return {
    ...x,
    user_type: MEMBER_TYPE[x.user_type],
    provider: x.social_type === 'default' ? '信箱註冊' : x.social_type,
  };
}

export default function ExportCsv({filters, children, onClean, filename}) {
  const csvInstance = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [actions] = useOutlet('actions');
  const headers = useRef([
    {label: '會員身份', key: 'user_type'},
    {label: '會員群組', key: 'group_name'},
    {label: '會員編號', key: 'id'},
    {label: '顧客名稱', key: 'name'},
    {label: '登入方式', key: 'provider'},
    {label: '行動電話', key: 'phone'},
    {label: '電子信箱', key: 'email'},
    {label: '地址', key: 'address'},
    {label: '備註', key: 'note'},
    {label: '剩餘加值點數', key: 'credits'},
    {label: '剩餘回饋紅利', key: 'bonus'},
  ]).current;

  const cleanup = useCallback(() => {
    setLoading(false);
    setData([]);
    onClean();
  }, []);

  useEffect(() => {
    // get count
    (async () => {
      let {start, end} = filters;
      if (isAllow(start, end) || true) {
        setLoading(true);
        try {
          let resp = await actions.getMembers({
            offset: 0,
            limit: 1,
          });
          setCount(resp.count);
        } catch (err) {}
        setLoading(false);
      }
    })();
  }, [filters.start, filters.end, actions]);

  const getRecords = useCallback(async () => {
    let {start, end, offset, limit} = filters;
    if (isAllow(start, end) || true) {
      try {
        if (offset !== 0 && offset >= count) {
          throw new ErrReport('最小數量已經超過全部數量了');
        }

        let resp = await actions.getMembers({
          ordering: 'created',
          // start,
          // end,
          offset,
          limit,
        });

        let results = resp.results.map(mapping);
        if (results.length === 0) {
          throw new ErrReport('查無資料');
        }
        setData(results);
      } catch (err) {
        console.log(err);
        errorHandler(err);
        cleanup();
      }
    }
  }, [filters, actions]);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        cleanup();
      }, 200);

      return () => {
        clearTimeout(timer);
        cleanup();
      };
    }
  }, [data]);

  return (
    <div>
      {children({
        onClick: () => getRecords(),
        loading,
        count,
      })}
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          headers={headers}
          data={data}
          filename={filename}></CSVLink>
      ) : null}
    </div>
  );
}
