import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Col, Row, Button, DatePicker, message, Card, Spin, Input} from 'antd';
import UserOutlined from '@ant-design/icons/UserOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import BarcodeOutlined from '@ant-design/icons/BarcodeOutlined';
import MemberCsv from '../Exports/MemberCsv';
import InvoiceCsv from '../Exports/InvoiceCsv';
import OrderCsv from '../Exports/OrderCsv';
import SalesCsv from '../Exports/SalesCsv';
import {isAllow} from '../Utils/ReportUtils';
import {useOutlet} from 'reconnect.js';

export default function ReportPage({}) {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    offset: 0,
    limit: 1000,
  });
  const [dates, setDates] = useState([]);
  const [profile] = useOutlet('user');

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooEarly = dates[0] && current.diff(dates[0], 'months', true) > 2;
    const tooLate = dates[1] && dates[1].diff(current, 'months', true) > 2;
    return tooEarly || tooLate;
  };

  const filtersChange = (obj) =>
    setFilters((prev) => ({
      ...prev,
      ...obj,
    }));

  if (!profile) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Block>
          <Row align="middle" gutter={[16, 30]}>
            <Col span={22}>
              <Title>報表中心</Title>
            </Col>
            <Col span={2}>
              {/* <Button
                loading={loading}
                onClick={() => this._getStatistics()}
              >
                刷新
              </Button> */}
            </Col>
            <Col span={3}>
              <div>過濾訂單時間</div>
            </Col>
            <Col span={13}>
              <DatePicker.RangePicker
                defaultValue={[
                  moment(filters.start, 'YYYY-MM-DD'),
                  moment(filters.end, 'YYYY-MM-DD'),
                ]}
                ranges={{
                  本日: [moment(), moment()],
                  本月: [moment().startOf('month'), moment().endOf('month')],
                }}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  setDates(val);
                }}
                onChange={(dates, dateStrings) => {
                  if (!dates) {
                    message.warning('需日期篩選才能匯出');
                  }

                  if (isAllow(dateStrings[0], dateStrings[1])) {
                    filtersChange({
                      start: dateStrings[0],
                      end: dateStrings[1],
                    });
                  } else {
                    message.warning('範圍在2個月以內');
                  }
                }}
                onOpenChange={(open) => {
                  if (open) {
                    setDates([]);
                  }
                }}
                placeholder={['開始日期', '結束日期']}
              />
            </Col>
            <Col span={8}>
              <div style={{color: '#aaa'}}>最多匯出2個月的資料</div>
            </Col>
            <Col span={3}>
              <div>匯出資料區間</div>
            </Col>
            <Col span={8}>
              <div
                style={{
                  display: 'flex',
                  border: '1px solid #d9d9d9',
                  borderRadius: 2,
                }}>
                <Input
                  type="number"
                  value={filters.offset + 1}
                  onChange={(e) => {
                    let value = e.target.value;
                    filtersChange({offset: value - 1});
                  }}
                  style={{border: 0, flex: '1 1 150px'}}
                  placeholder="最小"
                />
                <Input
                  style={{
                    pointerEvents: 'none',
                    flexBasis: 40,
                    border: 0,
                    background: 'white',
                    margin: '0 2px',
                  }}
                  placeholder="~"
                  disabled
                />
                <Input
                  value={filters.offset + filters.limit}
                  style={{
                    border: 0,
                    pointerEvents: 'none',
                    background: 'white',
                    flexBasis: 100,
                  }}
                  disabled
                />
              </div>
            </Col>
            <Col span={13}>
              <div style={{color: '#aaa'}}>
                最多匯出{filters.limit}筆資料，避免伺服器過於忙碌
              </div>
            </Col>
          </Row>
        </Block>
        <Row gutter={[16, 30]}>
          <Col span={6}>
            <MemberCsv
              onClean={() => setLoading(false)}
              filters={filters}
              filename="會員報表">
              {({onClick, count, loading}) => (
                <Spin spinning={loading}>
                  <Card
                    hoverable
                    style={{borderRadius: 10}}
                    onClick={() => {
                      setLoading(true);
                      onClick();
                    }}>
                    <Card.Meta
                      avatar={
                        <UserOutlined style={{color: '#999', fontSize: 25}} />
                      }
                      title="會員報表"
                      description="會員報表不會篩選日期"
                    />
                    {count ? <Badge>{count}</Badge> : null}
                  </Card>
                </Spin>
              )}
            </MemberCsv>
          </Col>
          <Col span={6}>
            <InvoiceCsv
              onClean={() => setLoading(false)}
              filters={filters}
              filename="發票報表">
              {({onClick, count, loading}) => (
                <Spin spinning={loading}>
                  <Card
                    hoverable
                    style={{borderRadius: 10}}
                    onClick={() => {
                      setLoading(true);
                      onClick();
                    }}>
                    <Card.Meta
                      avatar={
                        <BarcodeOutlined
                          style={{color: '#999', fontSize: 25}}
                        />
                      }
                      title="發票報表"
                      description=""
                    />
                    {count ? <Badge>{count}</Badge> : null}
                  </Card>
                </Spin>
              )}
            </InvoiceCsv>
          </Col>
          <Col span={6}>
            <SalesCsv
              onClean={() => setLoading(false)}
              filters={filters}
              filename="銷貨報表">
              {({onClick, count, loading}) => (
                <Spin spinning={loading}>
                  <Card
                    hoverable
                    style={{borderRadius: 10}}
                    onClick={() => {
                      setLoading(true);
                      onClick();
                    }}>
                    <Card.Meta
                      avatar={
                        <UnorderedListOutlined
                          style={{color: '#999', fontSize: 25}}
                        />
                      }
                      title="銷貨報表"
                      description="時間過濾為訂單的產生時間，且只有記錄已付款之訂單商品"
                    />
                    {count ? <Badge>{count}</Badge> : null}
                  </Card>
                </Spin>
              )}
            </SalesCsv>
          </Col>
          <Col span={6}>
            <OrderCsv
              onClean={() => setLoading(false)}
              filters={filters}
              filename="銷帳明細">
              {({onClick, count, loading}) => (
                <Spin spinning={loading}>
                  <Card
                    hoverable
                    style={{borderRadius: 10}}
                    onClick={() => {
                      setLoading(true);
                      onClick();
                    }}>
                    <Card.Meta
                      avatar={
                        <ProfileOutlined
                          style={{color: '#999', fontSize: 25}}
                        />
                      }
                      title="銷帳明細"
                      description="數量僅顯示訂單數量，不包含退款單"
                    />
                    {count ? <Badge>{count}</Badge> : null}
                  </Card>
                </Spin>
              )}
            </OrderCsv>
          </Col>
        </Row>
      </Wrapper>
    </Spin>
  );
}

const Wrapper = styled.div`
  margin: 20px;
`;

const Block = styled.div`
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  margin-bottom: 16px;
  padding: 25px;
  background-color: #ffffff;
`;

const Title = styled.div`
  color: #999;
  font-size: 20px;
  font-weight: 600;
`;

const Badge = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 4px;

  border-radius: 7px;
  font-size: 14px;
  line-height: 14px;
  color: white;
  background-color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
`;
