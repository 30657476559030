import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CSVLink} from 'react-csv';
import {message, Button, Spin} from 'antd';
import {MEMBER_TYPE} from '../Utils/MemberUtil';
import {errorHandler, ErrReport} from '../errors';
import {isAllow} from '../Utils/ReportUtils';
import {useOutlet} from 'reconnect.js';

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
function parse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {}
}

function mapping(x) {
  let detail = parse(x.invoice_detail);

  return {
    ...x,
    display_id: `'${x.display_id}`,
    order_created: x.order_created
      ? x.order_created.slice(0, 18).replace('T', ' ')
      : '-',
    buyer_type: MEMBER_TYPE[x.buyer_type],
    variants:
      x.item_process && Array.isArray(x.item_process)
        ? x.item_process.join(',') // item_process deprecated
        : x.variants // variants is current version
        ? x.variants
        : '',
  };
}

export default function ExportCsv({filters, children, onClean, filename}) {
  const csvInstance = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [token] = useOutlet('jwtToken');
  const [actions] = useOutlet('actions');

  const headers = useRef([
    {label: '訂單建立時間', key: 'order_created'},
    {label: '訂單編號', key: 'display_id'},
    {label: '顧客名稱', key: 'buyer_name'},
    {label: '會員類型', key: 'buyer_type'},
    {label: '商品編號', key: 'item_id'},
    {label: '商品名稱', key: 'name'},
    {label: '規格', key: 'variants'},
    {label: '成本', key: 'item_cost'},
    {label: '單價', key: 'item_price'},
    {label: '訂購數量', key: 'item_quantity'},
    {label: '商品價格', key: 'item_amount'},
    {label: '運費', key: 'fee'},
    {label: '訂單總金額', key: 'order_amount'},
  ]).current;

  const cleanup = useCallback(() => {
    setLoading(false);
    setData([]);
    onClean();
  }, []);

  useEffect(() => {
    // get count
    (async () => {
      let {start, end} = filters;
      if (token && isAllow(start, end)) {
        setLoading(true);
        try {
          let resp = await actions.getSalesReport({
            start,
            end,
            offset: 0,
            limit: 1,
            token,
          });

          setCount(resp.count);
        } catch (err) {
          console.log('error', err);
        }
        setLoading(false);
      }
    })();
  }, [filters.start, filters.end, token, actions]);

  const getRecords = useCallback(async () => {
    let {start, end, offset, limit} = filters;
    if (isAllow(start, end)) {
      try {
        if (offset !== 0 && offset >= count) {
          throw new ErrReport('最小數量已經超過全部數量了');
        }

        await actions.getMicroServiceToken();

        let resp = await actions.getSalesReport({
          ordering: '-created',
          start,
          end,
          offset,
          limit,
          token,
        });

        let results = resp.results.map(mapping);
        if (results.length === 0) {
          throw new ErrReport('查無資料');
        }

        setData(results);
      } catch (err) {
        errorHandler(err);
        cleanup();
        if (err.status === 403) {
          await actions.getMicroServiceToken();
        }
      }
    }
  }, [filters, token, actions]);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        cleanup();
      }, 200);

      return () => {
        clearTimeout(timer);
        cleanup();
      };
    }
  }, [data]);

  return (
    <div>
      {children({
        onClick: () => getRecords(),
        loading,
        count,
      })}
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          headers={headers}
          data={data}
          filename={filename}></CSVLink>
      ) : null}
    </div>
  );
}
