export const MEMBER_TYPE = {
  //一般
  normal: '一般會員',
  vip: '預繳會員',
  //企業
  enterprise: '一般企業',
  ent_vip: '企業預繳會員',
  monthly: '企業月結',
  ent_vip_monthly: '企業月結預繳會員',

  // note
  // 只有企業 有月結功能
};

export const MEMBER_OPTIONS = [
  {value: 'normal', label: '一般會員', color: '#D7BD23', category: 'normal'},
  {value: 'vip', label: '預繳會員', color: '#D7BD23', category: 'normal'},
  {value: 'enterprise', label: '一般企業', color: '#D7BD23', category: 'ent'},
  {value: 'ent_vip', label: '預繳企業', color: '#D7BD23', category: 'ent'},
  {value: 'monthly', label: '企業月結', color: '#D7BD23', category: 'ent'},
  {
    value: 'ent_vip_monthly',
    label: '企業月結預繳',
    color: '#D7BD23',
    category: 'ent',
  },
];
